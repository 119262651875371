

// Override bootstrap related variables
@import 'variables';

@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/mdbootstrap/scss/mdb-free';
@import '../node_modules/selectize-bootstrap4-theme/scss/selectize.bootstrap4';

// Common

// Preloader
#preloader {
  background-color: $white;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

// Preloader
#status {
  background-image: url('../images/preloading.gif');
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}

.custom-grey-text {
  color: $input-label-color;
}

@media (max-width: 992px) {
  .container {
    max-width: none;
    width: 100%;
  }
}

.modal-header {
  @extend .modal-header;

  background-color: $primary-color;
  color: $white;
}

.version-label {
  font-size: 10px;
}

.invisible-bg {
  background-color: transparent !important;
  border-color: transparent !important;
}

.md-select {
  // Remove arrow on Firefox
  -moz-appearance: none;
  // Remove arrow on Chrome
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $input-border-color;
  color: $input-color;
  font-family: inherit;
  font-size: $input-label-font-size;
  padding: $input-form-control-padding-top 0 $input-form-control-padding-bottom;
  width: 100%;

  // Remove arrow on IE10
  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
}

.md-selectfield {
  border: 0;
  position: relative;

  label {
    color: $input-label-color;
    cursor: text;
    font-size: $label-font-size;
    left: 0;
    position: absolute;
    top: $input-label-top;
    transform: $input-label-active-transform;
    transition: $input-label-transition;
  }

  .md-select {
    appearance: none;
  }

  &::after {
    border-left: .25em solid transparent;
    border-right: .25em solid transparent;
    border-top: .375em solid $black;
    content: '';
    height: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: .5em;
    top: .75em;
    width: 0;
  }
}

// Fix error with pagination used by dataTables plugin
.pagination .page-item.active .page-link,
.pagination .page-item.active .page-link:hover {
  background-color: $primary-color;
  color: $white;
}

// Fix text wrapping when release notes are displayed
pre {
  white-space: pre-wrap;
  word-break: normal;
}
// To set swal colors we need to initialize
.swal2-progresscircle {
  background-color: $swal-progress-bg-color;
}

// Search input on DataTable
.dataTables_filter input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $input-border-color;
  border-radius: 0;
  box-shadow: none;
  box-sizing: content-box;
  color: $input-color;
  font-family: inherit;
  font-size: $input-label-font-size;
  outline: none;

  // Focused input style
  &:focus:not([readonly]) {
    border-bottom: 1px solid $input-md-focus-color;
    box-shadow: 0 1px 0 0 $input-md-focus-color;
    // Focused label style
    + label {
      color: $input-md-focus-color;
    }
  }
}

// Length select on DataTable
.dataTables_length {
  border: 0;
  position: relative;

  .custom-select {
    // Remove arrow on Firefox
    -moz-appearance: none;
    // Remove arrow on Chrome
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid $input-border-color;
    color: $input-color;

    // Remove arrow on IE10
    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }
}

// Notifications dynamic card columns
.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
}

// Billing
.font-bold {
  font-weight: bold;
}

.hidden {
  display: none;
  visibility: hidden;
}

// Admin
.no-border {
  border: 0;
  box-shadow: none;
}

.apk-red {
  color: $red;
}

.apk-yellow {
  color: $yellow;
}

.apk-green {
  color: $green;
}

// First steps
.stepper {
  list-style: none;
  list-style-type: none;
  padding-left: 0;
}

.stepper:not(.horizontal) .step.active::before,
.stepper:not(.horizontal) .step.done::before,
.stepper.horizontal .step.active .step-title::before,
.stepper.horizontal .step.done .step-title::before {
  background-color: $primary-color;
}

// Embedded sign up form style
.signup-btn-theme {
  background-color: $signup-embedded-btn-bg-color;
  color: $signup-embedded-btn-color;
  font-weight: bold;
}
.quicksignup-theme {
  background-color: $quicksignup-primary-color;
  color: $white;
}
.quicksignup-primary-color {
  color: $quicksignup-primary-color;
}
.quicksignup-secondary-color {
  color: $quicksignup-backdrop-color;
}
.quicksignup-backdrop {
  background-color: $quicksignup-backdrop-color;
}
