
$primary: #4caf08;
$primary-color: #4caf08;
$default-color: #4caf08;
$swal-progress-bg-color: #a1daa5;
$roboto-font-path: '/scripts/mdbootstrap/font/roboto/';
$signup-embedded-btn-color: rgb(255, 180, 8);
$signup-embedded-btn-bg-color: rgb(1, 22, 39);
$quicksignup-primary-color: rgb(28, 119, 97);
$quicksignup-backdrop-color: rgb(41, 46, 55);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  xxl: 1360px,
  xxxl: 1600px
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1368px,
  xxxl: 1600px
);
